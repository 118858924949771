import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { Banner, BottomBanner } from '~components/banners';
import { stringFromUserAccessLevel } from '~utils/playlist-helpers';
import { isFeatureEnabled, FEATURE_FLAGS } from '~utils/feature-helpers';
import { arePathsEqual } from '~utils/url-helper';

const IS_BANNER_AT_BOTTOM_FEATURE_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BANNER_AT_BOTTOM);

const query = graphql`
  {
    prismicBanner {
      data {
        show_marquee_banner
        marquee_animation_speed
        marquee_texts {
          marquee_text {
            html
          }
        }
        body {
          ... on PrismicBannerDataBodyBannerData {
            primary {
              uid
              banner_text {
                html
                text
                raw
              }
              banner_text_color
              banner_secondary_text {
                html
                text
              }
              banner_secondary_text_color
              banner_image {
                alt
                url
              }
              button_title
              banner_link
              button_background_color
              banner_is_announcement
              button_text_color
              banner_background_color
              close_icon_color
              banner_subscription_level
              appear_always
            }
          }
        }
      }
    }
  }
`;

const Banners = () => {
  const { session } = useUserContext();
  const { isUserStateLoading, isAdmin, user } = session;
  const { access_level } = user;

  const { prismicBanner } = useStaticQuery(query);
  const { pathname } = useLocation();

  const { data } = prismicBanner || {};

  const {
    body: bannerBody,
    show_marquee_banner,
    marquee_texts,
    marquee_animation_speed
  } = data || {};

  const banners =
    bannerBody &&
    bannerBody.map(banner => ({
      ...banner.primary
    }));

  const userSubscriptionPlanAsString = stringFromUserAccessLevel(access_level);

  const adminBanner = banners && banners.find(v => v.banner_subscription_level === 'Admin');

  const userSubscriptionLevelBanner =
    banners && banners.find(v => v.banner_subscription_level === userSubscriptionPlanAsString);

  const siteWideBanner = banners && banners.find(v => v.banner_subscription_level === 'Everyone');

  const banner = () => {
    if (!isUserStateLoading && isAdmin && adminBanner) {
      return adminBanner;
    }
    if (!isUserStateLoading && userSubscriptionLevelBanner) {
      return userSubscriptionLevelBanner;
    }
    if (!isUserStateLoading && siteWideBanner) {
      return siteWideBanner;
    }
    return null;
  };

  let bannerProps = banner();

  // if the call-to action path equals to the current path, no need to show the banner
  if (arePathsEqual(pathname, bannerProps?.banner_link)) {
    bannerProps = null;
  }

  // note that marquee banner only supports when the bottom banner feature is available only
  const hasBanners =
    (IS_BANNER_AT_BOTTOM_FEATURE_ENABLED && show_marquee_banner) || bannerProps !== null;

  if (isUserStateLoading || !hasBanners) {
    return null;
  }

  if (IS_BANNER_AT_BOTTOM_FEATURE_ENABLED) {
    return (
      <BottomBanner
        {...bannerProps}
        show_marquee_banner={show_marquee_banner}
        marquee_texts={marquee_texts}
        marquee_animation_speed={marquee_animation_speed}
      />
    );
  }

  if (bannerProps) {
    return <Banner {...bannerProps} />;
  }

  return null;
};

Banners.propTypes = {};

Banners.defaultProps = {};

export default Banners;
